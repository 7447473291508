<script lang="ts">
	import { browser } from '$app/environment';
	import { PUBLIC_GOOGLE_ANALYTICS_ID } from '$env/static/public';
	import { loadMyTeams } from '$lib/components/component-functions';
	import HandlerNotifications from '$lib/components/handlers/HandlerNotifications.svelte';
	import HandlerTags from '$lib/components/handlers/HandlerTags.svelte';
	import HandlerUsage from '$lib/components/handlers/HandlerUsage.svelte';
	import type { SavedDeviceIds } from '$lib/components/models';
	import MolComingSoonModal from '$lib/components/mols/MolComingSoonModal.svelte';
	import {
		createLocalStorage,
		disableWarnings,
		persist
	} from '$lib/svelte-persistent-store-main/src/index';
	import { GoogleAnalytics } from '@beyonk/svelte-google-analytics';
	import '@fontsource/inter/400.css';
	import '@fontsource/inter/600.css';
	import '@fontsource/inter/700.css';
	import '@fontsource/lato/400.css';
	import '@fontsource/lato/700.css';
	import dayjs from 'dayjs';
	import 'dayjs/locale/en';
	import 'dayjs/locale/fr';
	import type { Subscription, Team, User } from 'fixee-server';
	import type { BrowserPermissions } from 'fixee-server/lib/services/portal/portal.shared';
	import { onDestroy, onMount, setContext } from 'svelte';
	import { Toaster } from 'svelte-french-toast';
	import { _, locale } from 'svelte-i18n';
	import { writable } from 'svelte/store';
	import { fade } from 'svelte/transition';
	import { pwaInfo } from 'virtual:pwa-info';
	import {
		initFirebase,
		requestNotificationPermission,
		subscribeSwToPushNotifications
	} from '../api/firebase';
	import useFixeeClient from '../api/fixee-client';
	import { inDevelopment, inProduction } from '../env';
	import '../i18n';
	import { fallbackLocale } from '../i18n';
	import {
		SELECTED_LOCALE,
		UNIQUE_DEVICE_ID,
		currentBrowserSupportsPush,
		iOS,
		isClientSide,
		randomUUID
	} from '../utils';
	import {
		getApiStatusContext,
		getBrowserPermissionsContext,
		getGlobalVarsContext,
		getTeamsContext,
		getWindowSizeContext,
		type ApiStatus,
		type GlobalVars,
		type RGPDAccept,
		type WindowSize
	} from './contexts';
	// import { registerSW } from 'virtual:pwa-register';
	import { page } from '$app/stores';
	import MolVideoPermissionsWarning from '$lib/components/mols/MolVideoPermissionsWarning.svelte';
	import 'flag-icons/css/flag-icons.min.css';
	import './styles.css';

	// https://vite-pwa-org.netlify.app/guide/auto-update.html#automatic-reload
	// This causes a build error in staging for now ?? https://gitlab.com/fixly/fixly-web/-/jobs/5048319515
	// registerSW({ immediate: true });

	// The warnings are supposed to be hidden, they are not really for some reason : https://github.com/MacFJA/svelte-persistent-store/issues/9
	disableWarnings();

	// Handle dark theme
	const theme = persist(writable<'dark' | 'light'>('light'), createLocalStorage(), 'theme');
	setContext('theme', theme);

	let loading = true;
	$: if (browser) {
		setTimeout(() => {
			loading = false;
		}, 300);
	}

	$: {
		if (browser) {
			if ($theme === 'dark') {
				// Disable dark theme for now ?
				console.log('Dark theme is disabled for now');
				// document.documentElement.classList.add('dark');
			} else {
				document.documentElement.classList.remove('dark');
			}
		}
	}

	// Handle CGU consent, should cookie banner if not accepted
	const RGPDAccept = persist(writable<RGPDAccept>({}), createLocalStorage(), 'RGPDAccept');
	setContext('RGPDAccept', RGPDAccept);

	// Update the global dayjs locale
	$: dayjs.locale($locale || fallbackLocale);

	// Handle permissions
	setContext(
		'permissions',
		persist(
			writable<BrowserPermissions>({
				camera: 'unknown',
				microphone: 'unknown',
				notifications: 'unknown',
				geolocation: 'unknown'
			}),
			createLocalStorage(),
			'permissions'
		)
	);
	const permissions = getBrowserPermissionsContext();

	const handleMyPermissions = async () => {
		const names = ['microphone', 'notifications', 'camera', 'geolocation'];

		for (const name of names) {
			try {
				const perm = await navigator.permissions.query({
					name: name as any
				});

				$permissions[name as keyof BrowserPermissions] = perm.state;
				perm.onchange = (event) => {
					const state = (event.target as any)?.state;
					if (state) {
						$permissions[name as keyof BrowserPermissions] = perm.state;
						console.log('Permission changed', name, perm.state);

						if (name === 'notifications' && perm.state === 'granted') {
							// We "request" the permission, this is actually just to trigger the creation of the subscription and save it in the database
							requestNotificationPermission($_);
						}
					}
				};
			} catch (error) {
				console.log('Browser does not support permissions API for', name);
				// For firefox for example, which does not suport "camera" and "microphone" permission query, we set it to prompt always.
				// Otherwise there is a risk that the user denies the permission, we save it in the localstorage and don't ask again because of that even if the didn't check "remember this choice".
				// In this case the user can be prompted again after a refresh
				$permissions[name as keyof BrowserPermissions] = 'prompt';
			}
		}
	};

	// Handle current user and other contexts
	const currentUser = persist(
		writable<User | undefined>(undefined),
		createLocalStorage(),
		'currentUser'
	);
	setContext('currentUser', currentUser);
	const currentTeam = persist(
		writable<Team | undefined>(undefined),
		createLocalStorage(),
		'currentTeam'
	);
	setContext('currentTeam', currentTeam);
	const currentSubscription = persist(
		writable<Subscription | undefined>(undefined),
		createLocalStorage(),
		'currentSubscription'
	);
	setContext('currentSubscription', currentSubscription);
	setContext('teams', writable([]));
	setContext('apiStatus', writable<ApiStatus>('disconnected'));
	setContext(
		'deviceIds',
		persist(
			writable<SavedDeviceIds>({ audioEnabled: true, videoEnabled: true }),
			createLocalStorage(),
			'deviceIds'
		)
	);
	// A general context for leftover variables
	setContext('globalVars', writable<GlobalVars>({}));
	setContext(
		'windowSize',
		writable<WindowSize>({
			height: 0,
			width: 0
		})
	);

	const teams = getTeamsContext();
	const apiStatus = getApiStatusContext();
	const windowSize = getWindowSizeContext();
	const globalVars = getGlobalVarsContext();

	const setWindowValues = () => {
		if (!isClientSide()) return;
		$windowSize = {
			width: window.innerWidth,
			height: window.innerHeight
		};
	};

	$: onCurrentTeamChanged($currentTeam);

	const onCurrentTeamChanged = (team?: Team) => {
		$currentSubscription = team?.subscription;
	};

	const onTeamPatched = (team: Team) => {
		if ($currentTeam?.id === team.id) {
			$currentTeam = team;
		}
		$teams = $teams.map((t) => (t.id === team.id ? team : t));
	};

	const loadPwaModal = async () => {
		if (browser) {
			// Disable it for now, we use autoUpdate instead of prompt
			// MolReloadPwaModal = (await import('$lib/components/mols/MolReloadPWAModal.svelte'))
			// 	.default;
		}
	};

	// When the lang changes, we update the user lang, we also update it when the page loads, this is especially useful for older user that won't toggle their lang to et the right one on the server
	$: currentUserId = $currentUser?.id; // It's important to have this, otherwise updateUserLang() would be triggered in a loop
	$: if ($locale && currentUserId && $apiStatus === 'authenticated') updateUserLang();
	const updateUserLang = async () => {
		if (currentUserId) {
			$currentUser = await useFixeeClient().service('user').patch(currentUserId, {
				lang: $locale
			});
		}
	};

	let MolReloadPwaModal: any | undefined = undefined;
	onMount(async () => {
		// If there is no selectedLocale, we use the browser locale by default
		const selectedLocale = window.localStorage.getItem(SELECTED_LOCALE);
		if (selectedLocale) $locale = selectedLocale;

		// This is important for push notification subscription, to make the device unique instead of relying on the user agent
		const uniqueDeviceId = window.localStorage.getItem(UNIQUE_DEVICE_ID);
		if (!uniqueDeviceId) window.localStorage.setItem(UNIQUE_DEVICE_ID, randomUUID());

		initFirebase();
		subscribeSwToPushNotifications();

		handleMyPermissions();

		window.addEventListener('resize', setWindowValues);
		setWindowValues();

		// Here we make sure to set the notification permission status, this is useful for browsers that don't support the permissions API like SAFARI de ses morts.
		// So we just set the value to the current Notification.permission value and it's nice
		// On iOS (and other platforms maybe) Notification is not in the window, we have to check this
		if (currentBrowserSupportsPush()) {
			$permissions.notifications =
				Notification.permission === 'default' ? 'prompt' : Notification.permission;
		}

		// Prevent zoom on the page https://stackoverflow.com/a/39711930
		if (iOS()) {
			document.addEventListener('gesturestart', function (e) {
				if (inDevelopment())
					console.log(
						'Gestures have been disabled for a better experience on iOS, make it available if we need to zoom in an image, then disable it again'
					);
				e.preventDefault();
			});
		}

		useFixeeClient().service('team').on('patched', onTeamPatched);

		// Handle events authenticated/logout, connect/disconnect
		useFixeeClient().io?.on('disconnect', (reason: any) => {
			console.log('Disconnected from FIXEE server : ', reason);
			$apiStatus = 'disconnected';
		});
		useFixeeClient().io?.on('connect', async () => {
			$apiStatus = 'connected';
		});

		useFixeeClient().on('authenticated', async (authResult) => {
			$apiStatus = 'authenticated';
			$currentUser = authResult.user;
			$teams = await loadMyTeams();

			// Set the $currentTeam to the individual team of the user if none is selected OR if we can't find the currentTeam in the teams that the user should have
			// It is useful when you change account on the same computer and "logout" wasn't properly triggered
			if (!$currentTeam || !$teams.find((t) => t.id === $currentTeam?.id)) {
				// By default we open the personal space
				$currentTeam = $teams.find((team) => team.individualId === $currentUser?.id)!;

				// If the user log in and has a team and his personal space, we consider that we should open the team, not the personal space
				if ($teams.length > 1) {
					$currentTeam = $teams.find((team) => team.individualId !== $currentUser?.id)!;
				}
			} else {
				// If currentTeam already has a value, we still "refresh" it by getting the team from the list of teams. This way we get the new amount of credits for example (instead of it being saved in the cookie and not updating well)
				$currentTeam = $teams.find((t) => t.id === $currentTeam?.id);
			}

			// When the user logs in, if the permission is already granted, we refresh the subscription. Maybe it's a little overkill but we're making sure the user is subscribed
			if ($permissions.notifications === 'granted') {
				requestNotificationPermission($_);
			}
		});
		useFixeeClient().on('logout', async (authResult) => {
			$apiStatus = 'connected';
			$currentUser = undefined;
			$currentTeam = undefined;
			$teams = [];
		});

		loadPwaModal();
	});

	onDestroy(() => {
		useFixeeClient().service('team').off('patched', onTeamPatched);

		// onDestroy can be called on the server side renderering and at this time window is undefined, it's a little weird but it's good to check for isClientSide()
		if (isClientSide()) {
			window.removeEventListener('resize', setWindowValues);
		}
	});

	// Example here on how to handle the manifest string https://github.com/vite-pwa/sveltekit/blob/84c4c20572c7ab364df34ab205fd9fdf263a635a/examples/sveltekit-ts/src/routes/%2Blayout.svelte#L12
	$: webManifest = pwaInfo ? pwaInfo.webManifest.linkTag : '';

	// Very important, for the client of our client, we don't show the tenant logo, it's especially important when a client has customization enabled, we don't want his client to see our logo
	$: canShowTenantLogo = !$page.route.id?.startsWith('/i/') && !$page.route.id?.startsWith('/a/');
</script>

<svelte:head>
	<title>{$_('head.title')}</title>
	<meta name="description" content={$_('head.description')} />

	{#if browser}
		{@html webManifest}
	{/if}
</svelte:head>

<MolVideoPermissionsWarning />

<!-- Using an absolute right under the body is good because we can easily use the full height of the
screen AND on mobile we don't have issues with the url bar, it's always shown which is good.
Otherwise it would be annoying to have to scroll down to see the bottom buttons bar -->
<!-- HandlerTags has to be up top because the portal uses this layout and we wan't to show tags in this portal -->
<HandlerTags currentTeam={$currentTeam}>
	<HandlerNotifications currentTeam={$currentTeam}>
		<HandlerUsage {currentTeam} {currentSubscription} {teams} />
		<div class="absolute top-0 bottom-0 left-0 right-0 flex flex-col">
			<slot />

			{#if browser}
				<!-- <MolReloadPwaModal /> -->
				<!-- <svelte:component this={MolReloadPwaModal} /> -->
			{/if}

			<MolComingSoonModal />
		</div>

		<!-- We don't show the page until it's loaded because there are some UI glitches, like the font is not loaded yet -->
		{#if loading && canShowTenantLogo}
			<div
				out:fade|global={{ duration: 150 }}
				class="absolute top-0 bottom-0 left-0 right-0 bg-white z-50 flex justify-center items-center"
			>
				<img
					class="w-28 top-1/2 left-1/2 translate-1/2"
					alt="logo"
					src="/images/logos/fixee_logo_transparent.png"
				/>
			</div>
		{/if}
	</HandlerNotifications>
</HandlerTags>

<!-- https://github.com/beyonk-group/svelte-google-analytics#the-enabled-property-optional Handle RGPD by calling init() later -->
{#if inProduction()}
	<GoogleAnalytics properties={[PUBLIC_GOOGLE_ANALYTICS_ID]} />
{/if}

<Toaster position={'top-right'} toastOptions={{ duration: 6000 }} />
